<template>
  <div class="TrueTopic1">
    <EduHeader />
    <main class="containerMain">
      <NavInfo />
      <div class="containerMainRight" style="padding-left: 60px">
        <div class="TrueTopic1Center">
          <div class="TrueTopic1Center-Title">近期答题报告</div>
          <div v-if="examList.length > 0" class="TrueTopic1Center-item">
            <dl>
              <dd
                v-for="(examItem, examIndex) in examList"
                :key="examIndex"
                :class="{ on: examIndex == active }"
                @click="setActive(examIndex)"
              >
                {{ examItem.created }}
              </dd>
              <!-- <dd>2022/02/02</dd>
              <dd>2022/02/02</dd>
              <dd>2022/02/02</dd>
              <dd>2022/02/02</dd>
              <dd>2022/02/02</dd> -->
            </dl>
          </div>
          <div v-if="examData" class="TrueTopic1CenterTitle">
            <span>{{ examData.member.fullname }}</span>
            <i v-if="examData">得分：{{ examData.totalScore }}分</i>
          </div>
          <div class="TrueTopic1Item">
            <div class="fl" @click="afreshTheQuestion">重新做题</div>
            <div v-if="id > 0" class="fr" @click="cancellation">撤销交卷</div>
            <!-- <div class="fr" @click="afreshTheQuestion">重新做题</div> -->
          </div>
          <el-empty
            v-if="examList.length == 0"
            description="暂无数据"
          ></el-empty>
          <div v-if="examData" class="TrueTopic1Centent">
            <div class="TrueTopic1Centent-time">
              <div class="fr">
                试卷用时：{{ secondsToTimeStr(examData.spend) }}
              </div>
            </div>
            <div class="TrueTopic1Centent-title">{{ examData.exam.title }}</div>
            <div class="TrueTopic1Centent-mt">
              <div class="mt-dl" v-if="examData">
                <div class="mtTas-fl" ref="mtTasCenter">
                  <dl ref="mtTasItem">
                    <dd
                      v-for="(
                        sectionItem, sectionAnswerIndex
                      ) in examData.sectionAnswerList"
                      :class="{ on: sectionAnswerIndex == sectionIndex }"
                      :key="sectionAnswerIndex"
                      @click="setSectionActive(sectionAnswerIndex)"
                    >
                      {{ sectionItem.section.title }}
                    </dd>
                    <!-- <dd>单选题</dd>
                    <dd>单选题</dd>
                    <dd>单选题</dd> -->
                  </dl>
                </div>
                <div class="mtTasBut" v-if="offsetWidth2 > offsetWidth1">
                  <span style="color: #666" v-if="intInfo === 0">&lt;</span>
                  <span v-else @click="tasButFn(1)">&lt;</span>
                  <span v-if="intInfo < wInt - 1" @click="tasButFn(2)"
                    >&gt;</span
                  >
                  <span style="color: #666" v-else>&gt;</span>
                </div>
              </div>
              <!-- {{
                examData.sectionAnswerList[sectionIndex].section
                  .examSectionTopicVOList
              }}

              {{ examData.sectionAnswerList[sectionIndex] }} -->
            </div>
            <div>
              <topic
                v-for="(topicItem, topicIndex) in examData.sectionAnswerList[
                  sectionIndex
                ].answerList"
                :key="topicIndex"
                :answerData="topicItem"
                :answerIndex="topicIndex + 1"
                :isShowQuestionTitle="false"
                wrongTopicRefKind="Exam"
                :wrongTopicRefId="examData.exam.categoryId"
                :valid="
                  examData.sectionAnswerList[sectionIndex].section
                    .examSectionTopicVOList[topicIndex].topicVO.explain.valid
                "
                refKind="Exam"
                style="margin-top: 20px"
              />
              <!-- + questionCount  -->
            </div>

            <!-- <div class="TrueTopic1Centent-rtitle">
                <div class="fl">
                  <span>计算分析</span>
                  （共10题 共14分）
                </div>
                <div class="fr">
                  <div class="flw">下载答题报告：</div>
                  <span><img src="~@/assets/TrueTopic/pdf.png" /></span>
                  <div class="flw1">得分：45分</div>
                  <div class="flw1">
                    <i><img src="~@/assets/TrueTopic/icon-6.png" /></i
                    >用时：0：20：11
                  </div>
                </div>
              </div>
              <div class="TitleReport1CenterSelect">
                <div class="slect">
                  <span>问题1</span>
                  <i><img src="~@/assets/TrueTopic/select-1.png" /></i>
                </div>
                <div class="slect on">
                  <span>问题1</span>
                  <i><img src="~@/assets/TrueTopic/select-2.png" /></i>
                </div>
              </div>
              <div class="TitleReportItem1">
                <i></i>
                错误
                <div class="on"><span></span>查看解析</div>
                <div><span></span>查看解析</div>
              </div>
              <div class="TitleReportItem2">
                <i></i>
                错误
                <div class="on"><span></span>查看解析</div>
                <div><span></span>查看解析</div>
              </div>
              <div class="TrueTopicItem">
                <div class="TrueTopicItemTitle">
                  <div class="TrueTopicItemTitleFl">
                    <i><img src="~@/assets/TrueTopic/iconshang.png" /></i>
                    <span>问答题</span>
                    <div>
                      <i><img src="~@/assets/TrueTopic/iconjiao.png" /></i
                      ><span>加入收藏夹</span>
                    </div>
                  </div>
                  <div class="TrueTopicItemTitleFr">用时间：0：02：45</div>
                </div>
                <div class="TrueTopicItemFont">
                  <div class="TrueTopicItemFontTitle">什么是贷款？</div>
                  <div class="TrueTopicItemFontInfo">
                    贷款（电子借条信用贷款 [5] ）简单通俗的理解，就是需要 利息
                    的借钱。. 贷款是 银行 或其他 金融机构
                    按一定利率和必须归还等条件出借 货币资金
                    的一种信用活动形式。. 广义的贷款指贷款、 贴现 、 透支
                    等出贷资金的总称。. 银行通过贷款的方式将所集中的货币和
                    货币资金 投放出去，可以满足社会 扩大再生产
                    对补充资金的需要，促进经济的发展，同时，银行也可以由此取得贷款
                    利息收入 ，增加银行自身的积累。
                  </div>
                </div>
                <div class="TrueTopicItemp30">
                  <div class="fl">得 0分 共2.5分</div>
                  <div class="fr"></div>
                </div>
                <div class="TrueTopicItemFont">
                  <div class="TrueTopicItemFontTitle">
                    贷款（电子借条信用贷款 [5] ）简单通俗的理解，就是需要 利息
                    的借钱。. 贷款是 银行 或其他 金融机构
                    按一定利率和必须归还等条件出借 货币资金
                  </div>
                  <div class="TrueTopicItemFontInfo">
                    <video src="" controls></video>
                    <div class="TrueTopicItemFontpl">
                      <div class="TrueTopicItemFontpl-title">错题解析视频</div>
                      <div class="TrueTopicItemFontpl-but">
                        <button @click="purchaseFn()">购买</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Topic from "@/components/TopicResult/Topic.vue";
import EduHeader from "@/components/EduHeader";
import NavInfo from "@/components/NavInfo";
import { secondsToTimeStr } from "@/utils/validate";
import {
  getTopicResultByRefKingAndId,
  getMemberExam,
  deleteExamTopicResult,
} from "@/api/memberTopicResultController";

export default {
  name: "TrueTopic1",
  data() {
    return {
      intInfo: 0,
      offsetWidth1: 0,
      offsetWidth2: 0,
      wInt: 0,
      activeName: "Teaching",
      show: false,
      info: "对",
      id: -1,
      examData: null,
      examList: [],
      examListQuery: {
        __page: 0,
        __pageSize: 6,
        examId: -1,
        __orderBy: "-created",
      },
      examId: -1,
      sectionIndex: 0,
      active: 0,
      questionCount: 0,
    };
  },
  created() {
    this.id = this.$route.params && this.$route.params.id;
    this.examId = this.$route.query.examId;
    this.examListQuery.examId = this.examId;
    this.getMemberExam();
    if (this.id > 0) {
      this.getTopicResultByRefKingAndId();
    }
  },
  methods: {
    tasButFn(row) {
      if (row === 1) {
        this.intInfo--;
      }
      if (row === 2) {
        this.intInfo++;
      }
      this.$refs.mtTasItem.style.left =
        -(this.intInfo * this.offsetWidth1) + "px";
    },
    secondsToTimeStr(spend) {
      return secondsToTimeStr(spend);
    },
    setActive(index) {
      this.active = index;
      this.id = this.examList[index].id;
      this.getTopicResultByRefKingAndId();
    },
    setSectionActive(index) {
      this.sectionIndex = index;
      this.questionCount = 0;
      for (let i = 0; i < index; i++) {
        this.questionCount +=
          this.examData.sectionAnswerList[
            i
          ].section.examSectionTopicVOList.length;
      }
    },
    afreshTheQuestion() {
      this.$router.push({
        path: "/NewTopic/" + this.examId,
      });
    },
    cancellation() {
      deleteExamTopicResult(this.id).then((res) => {
        if (res.code == 20000) {
          this.$baseMessage("撤销成功", "success", "vab-hey-message-success");
          this.active = 0;
          this.id = -1;
          this.getMemberExam();
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    topicConcatAnswer(answer, topicItem) {
      topicItem.answers = [answer];
      return topicItem;
    },
    getMemberExam() {
      getMemberExam(this.examListQuery).then((res) => {
        if (res.code == 20000) {
          this.examList = res.data;
          if (this.id < 0) {
            if (this.examList.length > 0) {
              this.id = this.examList[this.active].id;
              this.getTopicResultByRefKingAndId();
            }
          }
          var _this = this;
          setTimeout(function () {
            _this.offsetWidth1 = _this.$refs.mtTasCenter.offsetWidth;
            _this.offsetWidth2 = _this.$refs.mtTasItem.offsetWidth;
            _this.wInt = Math.ceil(_this.offsetWidth2 / _this.offsetWidth1);
          }, 500);
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    getTopicResultByRefKingAndId() {
      getTopicResultByRefKingAndId("exam", this.id).then((res) => {
        if (res.code == 20000) {
          this.examData = res.data;
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    showBu(row) {
      this.info = row;
      this.show = false;
    },
    showFn() {
      this.show = !this.show;
    },
  },
  components: {
    EduHeader,
    NavInfo,
    Topic,
  },
};
</script>

<style lang="scss" scoped>
.containerMain {
  background: #fafafa;
  position: relative;
  .containerMainRight {
    padding-left: 410px;
    .TrueTopic1Center {
      width: 1060px;
      margin: 0px auto;
      padding: 75px 0 15px 0;
      ul {
        li {
          padding-bottom: 20px;
        }
      }
      .FontTitle {
        font-weight: bold;
        font-size: 20px;
        font-weight: bold;
        color: #173d7c;
      }
      .TrueTopic1CenterTitle {
        font-weight: 400;
        color: #060606;
        line-height: 70px;
        height: 70px;
        span {
          float: left;
          font-size: 20px;
          font-weight: bold;
          color: #333333;
        }
        i {
          float: right;
          font-size: 24px;
          font-weight: bold;
          color: #060606;
          font-style: normal;
        }
      }
      .TrueTopic1Center-Title {
        font-weight: bold;
        font-size: 20px;
        color: #333333;
      }

      .page-center {
        height: 32px;
        background: url("~@/assets/TrueTopic/itembg.png") repeat-x left;
        text-align: center;
        margin-top: 30px;
        .itemPage {
          height: 30px;
          background: #ffffff;
          border: 1px solid #d9d9d9;
          opacity: 1;
          border-radius: 15px;
          display: inline-block;
          box-sizing: content-box;
          .left {
            float: left;
            background: url("~@/assets/TrueTopic/left.png") no-repeat center;

            cursor: pointer;
            padding: 0 15px;
            border-right: 1px solid #d9d9d9;
            height: 30px;
            width: 24px;
            box-sizing: content-box;
          }
          .right {
            float: right;
            background: url("~@/assets/TrueTopic/right.png") no-repeat center;
            cursor: pointer;
            padding: 0 15px;
            border-left: 1px solid #d9d9d9;
            height: 30px;
            width: 24px;
            box-sizing: content-box;
          }
          .center {
            padding: 0 70px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 30px;
            color: #575b66;
          }
          &:hover {
            border: 1px solid #8d0005;
            .left {
              border-right: 1px solid #8d0005;
            }
            .right {
              border-left: 1px solid #8d0005;
            }
            .center {
              color: #8d0005;
            }
          }
        }
      }
      .TrueTopic1Center-Font {
        margin-top: 20px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #060606;
      }
      .TrueTopic1Center-or {
        margin-top: 20px;
        border: 1px solid #d6d6d6;
        border-radius: 6px;
        dl {
          dd {
            .TrueTopic1Center-orDdTitle {
              padding: 20px 14px;
              label {
                cursor: pointer;
                display: block;
                position: relative;
                input {
                  float: left;
                  margin-top: 6px;
                }
                div {
                  padding-left: 35px;
                  padding-right: 35px;
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  line-height: 24px;
                  color: #060606;
                }
                i {
                  position: absolute;
                  right: 0px;
                  top: 5px;
                }
              }
            }
            .TrueTopic1Center-info {
              background: #e8e8e8;

              border-radius: 0px;
              padding: 20px 20px 20px 50px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 24px;
              color: #060606;
            }
          }
        }
      }
      .TrueTopic1Center-text {
        margin-top: 20px;
        textarea {
          height: 350px;
          width: 100%;
          border: 0;
          font-size: 16px;
          padding: 5px 0;
          line-height: 24px;
        }
      }
    }
  }
}
.TrueTopic1But {
  padding: 50px 0;
  text-align: center;
  button {
    height: 44px;
    font-size: 16px;
    border-radius: 8px;
    padding: 0 30px;
    margin: 0 10px;
    cursor: pointer;
    color: #333333;
    border: 0;
    &.but1 {
      background: #5a5a5d;
    }
    &.but2 {
      background: #ffcf00;
    }
  }
}

.TrueTopic1 {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    padding: 24px 40px 40px;
    font-size: 16px;
    color: $color-text-6;
    background-color: $color-background-5;
  }
}
.TrueTopic1Center-item {
  margin-top: 10px;
  dl {
    dd {
      height: 50px;
      background: #6a6a6a;
      border-radius: 8px;
      float: left;
      margin-right: 8px;
      font-size: 12px;
      font-weight: 400;
      line-height: 50px;
      padding: 0 20px;
      color: #ffffff;
      cursor: pointer;
      &.on {
        background-color: #ffcf00;
        color: #434343;
      }
    }
  }
  &:after {
    clear: both;
    display: block;
    content: "";
  }
}
.TrueTopic1Item {
  margin-top: 20px;
  .fl {
    float: left;
    font-weight: bold;
    line-height: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #636363;
    border-radius: 10px;
    border: 1px solid #173d7c;
    color: #fff;
    background-color: #173d7c;
    width: 149px;
    height: 50px;
    text-align: center;
  }
  .fr {
    float: right;
    cursor: pointer;
    width: 149px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #173d7c;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 50px;
    text-align: center;
    box-sizing: content-box;
  }
  &:after {
    clear: both;
    display: block;
    content: "";
  }
}
.TrueTopic1Centent {
  padding: 20px 60px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #707070;
  margin-top: 20px;
  .TrueTopic1Centent-time {
    .fr {
      float: right;
      background: url("~@/assets/TrueTopic/icon-6.png") no-repeat left;
      background-size: 16px;
      padding-left: 24px;
    }
    &:after {
      clear: both;
      display: block;
      content: "";
    }
  }
  .TrueTopic1Centent-title {
    font-size: 36px;
    font-weight: 400;
    color: #060606;
    text-align: center;
    line-height: 60px;
  }
  .TrueTopic1Centent-mt {
    margin-top: 20px;
  }
}
.mt-dl {
  border-bottom: 1px solid #707070;
  .mtTas-fl {
    float: left;
    width: 90%;
    overflow: hidden;
    font-size: 0;
    white-space: nowrap;
    position: relative;
    dl {
      white-space: nowrap;
      position: relative;
      left: 0;
      float: left;
      transition: all 0.3s ease;
      dd {
        padding: 0 20px;
        display: inline-block;
        border: 1px solid transparent;
        border-bottom: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        font-size: 24px;
        font-weight: 400;
        color: #575b67;
        line-height: 50px;
        cursor: pointer;
        &.on {
          background: #ffffff;
          border: 1px solid #707070;
          border-bottom: 1px solid transparent;
        }
      }
    }
  }
  .mtTas-fr {
    float: right;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 50px;
  }
  // .mtTas-fl{
  //     float: left;
  //     width: 80%;
  //     overflow: auto;
  //     font-size: 0;
  //     white-space: nowrap;
  //     position: relative;
  //     dl{
  //       white-space: nowrap;
  //       position: relative;
  //       left: 0;
  //       float: left;
  //       transition: all 0.3s ease;
  //       dd{
  //         padding: 0 20px;
  //         display: inline-block;
  //         border: 1px solid transparent;
  //         border-bottom: 0;
  //         border-top-left-radius: 4px;
  //         border-top-right-radius: 4px;
  //         font-size: 24px;
  //         font-weight: 400;
  //         color: #575B67;
  //         line-height: 50px;
  //         cursor: pointer;
  //         &.on{
  //           background: #ffffff;
  //           border: 1px solid #707070;
  //           border-bottom: 1px solid transparent;
  //         }
  //       }
  //     }
  //   }
  //   .mtTasBut{
  //     float: right;
  //     font-size: 12px;
  //     font-weight: 400;
  //     color: #666666;
  //     line-height: 50px;
  //   }
  &::after {
    clear: both;
    display: block;
    content: "";
  }

  // dl{
  //   dd{
  //     float: left;
  //     font-size: 24px;
  //     font-weight: 400;
  //     color: #575B67;
  //     padding: 0 20px;
  //     line-height: 56px;
  //     border: 1px solid transparent;
  //     border-bottom: 0;
  //     cursor: pointer;
  //     &.on{
  //       border-top: 1px solid #707070;
  //       border-left: 1px solid #707070;
  //       border-right: 1px solid #707070;
  //       border-top-left-radius: 4px;
  //       border-top-right-radius: 4px;
  //     }
  //   }
  // }
  // &:after {
  //   clear: both;
  //   display: block;
  //   content: "";
  // }
}
.TrueTopic1Centent-rtitle {
  line-height: 42px;
  .fl {
    float: left;
    font-size: 16px;
    font-weight: bold;
    color: #060606;
    span {
      font-weight: bold;
      margin-right: 10px;
    }
  }
  .fr {
    float: right;

    .flw {
      float: left;
      font-size: 16px;
      font-weight: 400;
      color: #636363;
    }
    .flw1 {
      float: left;
      font-size: 16px;
      font-weight: 400;
      color: #060606;
      margin-left: 30px;
      i {
        float: left;
        margin-right: 10px;
      }
    }
    span {
      float: left;
      font-size: 0;
    }
  }
  &:after {
    clear: both;
    display: block;
    content: "";
  }
}
.TitleReport1CenterSelect {
  padding: 10px 0;
  .slect {
    position: relative;
    float: left;
    height: 20px;
    background: #ffffff;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    border: 1px solid #707070;
    padding: 0 12px;
    span {
      float: left;
      font-size: 12px;
    }
    i {
      float: left;
      margin-left: 5px;
      font-size: 0;
      margin-top: 3px;
    }
    &::after {
      clear: both;
      display: block;
      content: "";
    }
    &.on {
      height: 20px;
      background: #8d0005;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      border: 1px solid #6d0004;
      color: #ffffff;
    }
  }
  &::after {
    clear: both;
    display: block;
    content: "";
  }
}
.TitleReportItem1 {
  padding: 15px;

  background: #ede2e3;
  border-radius: 6px;
  border: 1px solid #8d0005;

  i {
    float: left;
    width: 24px;
    height: 24px;
    display: block;
    background: url("~@/assets/TrueTopic/cuowu.png") no-repeat center;
    background-size: cover;
    margin-right: 10px;
  }
  div {
    float: right;
    font-size: 16px;
    font-weight: 400;
    color: #636363;
    cursor: pointer;
    span {
      background: url("~@/assets/TrueTopic/down.png") no-repeat center;
      display: block;
      float: left;
      margin-right: 8px;
      background-size: cover;
      width: 16px;
      height: 16px;
      margin-top: 3px;
    }
    &.on {
      span {
        background: url("~@/assets/TrueTopic/up.png") no-repeat center;
        background-size: cover;
      }
    }
  }
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

.TitleReportItem2 {
  padding: 15px;
  background: #dfe9df;
  border: 1px solid #307a35;
  border-radius: 6px;

  i {
    float: left;
    width: 24px;
    height: 24px;
    display: block;
    background: url("~@/assets/TrueTopic/zhengque.png") no-repeat center;
    background-size: cover;
    margin-right: 10px;
  }
  div {
    float: right;
    font-size: 16px;
    font-weight: 400;
    color: #636363;
    cursor: pointer;
    span {
      background: url("~@/assets/TrueTopic/down.png") no-repeat center;
      display: block;
      float: left;
      margin-right: 8px;
      background-size: cover;
      width: 16px;
      height: 16px;
      margin-top: 3px;
    }
    &.on {
      span {
        background: url("~@/assets/TrueTopic/up.png") no-repeat center;
        background-size: cover;
      }
    }
  }
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
.TrueTopicItem {
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  border: 1px solid #c3c3c3;
  margin-top: 10px;
  .TrueTopicItemTitle {
    height: 50px;
    border-bottom: 1px solid #c3c3c3;
    padding: 0 15px;
    .TrueTopicItemTitleFl {
      float: left;
      margin-top: 10px;
      span,
      i,
      div {
        float: left;
        margin-right: 10px;
      }
      i {
        margin-top: 5px;
      }
      span {
        font-size: 20px;
        font-weight: bold;
        color: #173d7c;
      }
      div {
        height: 30px;
        background: #636363;
        line-height: 30px;
        border-radius: 16px;
        padding: 0 10px;
        i {
          margin-top: 3px;
        }
        span {
          color: #ffffff;
          font-size: 12px;
          font-weight: normal;
          margin: 0;
        }
      }
    }
    .TrueTopicItemTitleFr {
      float: right;
      font-size: 12px;
      font-weight: 400;
      color: #060606;
      line-height: 50px;
    }
  }
  .TrueTopicItemFont {
    padding: 20px 30px;
    .TrueTopicItemFontTitle {
      font-size: 16px;
      font-weight: 400;
      color: #060606;
      line-height: 28px;
    }
    .TrueTopicItemFontInfo {
      font-size: 16px;
      font-weight: 400;
      color: #060606;
      line-height: 28px;
      margin-top: 15px;
      &:after {
        clear: both;
        display: block;
        content: "";
      }
      video {
        float: left;
        width: 280px;
      }
      .TrueTopicItemFontpl {
        padding-left: 300px;
        .TrueTopicItemFontpl-title {
          font-size: 16px;
          font-weight: 400;
          color: #636363;
        }
        .TrueTopicItemFontpl-but {
          margin-top: 10px;
          button {
            border-radius: 10px;
            border: 0;
            color: #ffffff;
            height: 44px;
            background: #173d7c;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            width: 120px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .TrueTopicItemp30 {
    padding: 15px 30px;
    border-top: 1px solid #c3c3c3;
    border-bottom: 1px solid #c3c3c3;
    line-height: 24px;
    div {
      font-size: 12px;
      font-weight: bold;
      color: #060606;
    }
    &:after {
      clear: both;
      display: block;
      content: "";
    }
  }
}
.mtTasBut {
  float: right;
  span {
    cursor: pointer;
    width: 24px;
    height: 24px;
    float: left;
    line-height: 24px;
    text-align: center;
    margin-top: 13px;
  }
}
</style>
